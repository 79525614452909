@media (max-width:1500px) {
    .container { max-width: 1200px; }
    .logo { margin-left: 0; }
    .navigation ul li { padding: 0 7px; }
    ul.bxslider li img { min-height: 500px; }
    .banner-content { max-width: 850px; padding: 35px 70px }
    .view-job-section { padding: 50px 0 85px; }
    .footer-bottom { padding: 60px 0 50px; }

    .banner-inner h2 { font-size: 34px; }
    .sidebar-view-job .button { font-size: 22px; padding: 10px 20px; }
}

@media (max-width:1279px) {
    .container { max-width: 960px; }
    body { font-size: 20px; line-height: 30px; }
    .button { font-size: 18px; padding: 8px 30px; }
    .banner-content h2,h2,.hot-job-title h2,.job-alerts h2,.banner-inner h2 { font-size: 28px; }
    .job-title h3,h3 { font-size: 22px; }
    .sidebar-view-job h4,h4 { font-size: 18px; }
    .button.btn-transparent { font-size: 18px; padding: 8px 30px; }
    .site-header .row { height: 100px; }
    .navigation ul li { padding: 0; }
    .navigation ul li a { font-size: 20px; }
    .navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { width: 140px; padding: 5px 0; }
    ul.sub-menu li { line-height: 16px !important; }
    ul.sub-menu li.open > ul.sub-menu, ul.sub-menu li:hover > ul.sub-menu { padding: 0;}
    .banner-content {  max-width: 750px; padding: 35px 50px; font-size: 18px; }

    .banner-content .button { margin-top: 5px; }
    .job-title { bottom: 5px; }
    .job-descreption { padding: 15px 25px; }

    .hot-job-section { padding: 40px 0; }
    .hot-job-title h2 { padding-bottom: 20px; }

    .acJSearchM ul li a { font-size: 16px; padding: 0 15px; }

    .footer-top { padding: 35px 0 45px; }
    .job-alerts h2 span { vertical-align: top; }

    .footer-bottom { padding: 50px 0 40px; }
    .footer-info .footer-call a, .footer-info .footer-mail a,.footer-info .footer-call span, .footer-info .footer-mail span { font-size: 24px; }
    .footer-info .footer-call span, .footer-info .footer-mail span { margin-right: 15px; }

    .banner-inner .row { height: 230px; }
    .banner-inner h2 { padding-right: 20px; }
    .content-section { padding: 50px 0; }

    .content-section .content-block h4 { font-size: 22px; padding-top: 20px; }
    .content-section .content-block ul { margin: 20px 0 0; }
    .content-section .content-block ul li { padding-left: 30px; }

    .sidebar { margin-left: 20px; margin-top: -190px; }
    .sidebar-view-job { padding: 30px 25px; }
    .sidebar-view-job h4 { line-height: 24px; }
    .sidebar-view-job .button { font-size: 18px; padding: 8px 20px; margin-top: 15px; margin-bottom: 0; }
    .sidebar-info { padding: 20px 25px 20px; font-size: 18px; line-height: 28px; }

}

@media (max-width:992px) {
    .container { max-width: 740px; }
    body { font-size: 18px; line-height: 26px; }
    .button.btn-transparent { font-size: 16px; padding: 6px 20px; }
    .button { font-size: 16px; padding: 7px 25px 6px; }
    .banner-content h2, h2, .hot-job-title h2, .job-alerts h2,.banner-inner h2 { font-size: 22px; }
    .job-title h3, h3 { font-size: 18px; }
    .sidebar-view-job h4, h4 { font-size: 16px; }
    .site-header .row { height: 80px; }
    .navigation ul li a { font-size: 14px; }
    .navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { width: 120px; }
    ul.sub-menu li { padding: 0 !important; }
    ul.bxslider li img { min-height: 370px; }
    .banner-content { max-width: 560px; padding: 20px 30px; font-size: 14px; }

    .view-job-section { padding: 40px 0 65px; }
    .job-image.img-cover { min-height: 120px; }
    .job-descreption { padding: 10px 20px; font-size: 15px; line-height: 22px; }

    .hot-job-section { padding: 30px 0; }

    .acJSearchM ul li { flex: 0 0 (100% /3); }

    .signup-text { font-size: 18px; line-height: 22px; }
    .footer-info .footer-call a, .footer-info .footer-mail a, .footer-info .footer-call span, .footer-info .footer-mail span { font-size: 22px; }

    .banner-inner .row { height: 150px; }
    .content-section { padding: 40px 0; }
    .sidebar { margin-left: 0; margin-top: -140px; }
    .sidebar-view-job { padding: 15px 20px 25px; }
    .sidebar-view-job .button { font-size: 16px; padding: 8px 15px; margin-top: 10px; }
    .sidebar-info { padding: 20px; font-size: 16px; line-height: 24px; margin-bottom: 0; }
    .content-section p { padding-bottom: 5px; }

    .content-section .content-block h4 { font-size: 20px; }
    .content-section .content-block ul { margin: 15px 0 0; }
    .content-section .content-block ul li {  padding-left: 25px; }
    .content-section .content-block ul li:before { left: 5px; top: 10px; }

    .signup-btn { text-align: left; padding-left: 30px; }
}

@media (max-width:767px) {
    .container { max-width: 100%; }
    body { font-size: 16px; line-height: 22px; }
    .button.btn-transparent {  font-size: 17px; padding: 10px 30px; }
    .button { font-size: 20px; padding: 10px 40px 9px; }
    .banner-content h2, h2 { font-size: 26px; }
    .job-title h3, h3 { font-size: 26px; }
    .logo { max-width: 190px; height: auto; }
    .site-header .row { height: 98px; }
    .site-header .col-sm-9.text-right { position: fixed; right: -230px; top: 0; height: 100%; width: 230px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background: #FC5131; text-align: left; overflow: auto; padding: 30px 0; margin: 0; overflow-x: hidden; box-shadow: 0px; }
    .open-menu .site-header .col-sm-9.text-right {  box-shadow: 0px 0px 20px rgba(0,0,0,0.5); }
    .mobile-icon { display: block; position: absolute; right: 23px; top: 22px; width: 55px; height: 55px; background: url(../media/mobile-icon.png); background-position: 0px 0px; background-repeat: no-repeat; }
    .open-menu .col-sm-9.text-right { right: 0; }
    .open-menu .site { left: -230px; }
    .site { -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
    .open-menu .site:before {  content: ""; position: absolute; display: block; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); z-index: 20; }
    .open-menu .mobile-icon { background-position: -61px 0px; }
    .navigation ul li { display: block; text-align: left; }
    .navigation ul li a { font-size: 18px; padding: 10px; }
    .navigation ul li.open > ul.sub-menu, .navigation ul li:hover > ul.sub-menu { width: 100%; position: relative; padding: 0 0 0 15px; }
    ul.sub-menu li.open > ul.sub-menu, ul.sub-menu li:hover > ul.sub-menu { left: 0; }
    ul.sub-menu li a,ul.sub-menu li.open > ul.sub-menu, ul.sub-menu li:hover > ul.sub-menu li a { padding: 4px 15px !important; }
    .navigation ul li.menu-item-has-children > a:before { content: "\f107"; position: absolute; display: inline-block; font-family: Font Awesome\ 5 Free; right: -15px; font-weight: 900; font-size: 25px; }

    .banner-content {  max-width: 100%; padding: 20px; font-size: 16px; line-height: 22px; border-radius: 0; background: transparent; }
    ul.bxslider li:before { background: rgba(0,0,0,0.7); }
    ul.bxslider li img { min-height: 425px; }
    .banner-content h2 { padding-bottom: 10px; }
    .banner-content .button { margin-top: 17px; }

    .view-job-section { padding: 0; }
    .view-job-section .col-md-4 { padding: 0; }
    .job-image.img-cover { min-height: 196px; border-radius: 0; }
    .job-descreption { padding: 30px 20px 10px; font-size: 14px; line-height: 20px; border-radius: 0; position: relative; z-index: 1; }

    .job-title { bottom: 12px; }
    .hot-job-section {  padding: 80px 0 58px; }
    .hot-job-title { text-align: center; }
    .hot-job-btn:after,.hot-job-title:before { content: none; }
    .hot-job-title h2{ font-size: 34px; padding-left: 0; padding-bottom: 5px }

    .acJSearchM ul li { flex-basis: 100%; padding-bottom: 0; }
    .acJSearchM ul li a { height: auto; display: block; background: transparent; border-bottom: 2px solid #ccc; border-radius: 0; padding: 25px 0; margin-bottom: 0; line-height: 20px; }

    .hot-job-btn { padding: 28px 0 0 0; position: relative; text-align: center !important; }
    .job-alerts { text-align: center; }
    .job-alerts h2 { font-size: 30px; }
    .job-alerts h2 span { display: block; margin-bottom: 10px; margin-right: 0; }

    .signup-text { font-size: 20px; line-height: 24px; text-align: center; }
    .signup-btn { padding: 30px 12px 20px; text-align: center; }
    .signup-btn .button { font-size: 20px; padding: 10px 40px; }

    .footer-bottom { padding: 75px 0 60px; }
    .footer-logo { margin-bottom: 10px; }
    .footer-mail { text-align: center !important; }
    .footer-info { padding: 0 18px; }
    .footer-info .footer-call a, .footer-info .footer-mail a  { font-size: 24px; line-height: 30px; }
    .footer-info .footer-call span, .footer-info .footer-mail span { font-size: 20px; line-height: 20px; }
    .footer-call { padding: 20px 0; }
    .footer-copyright ul { margin: 40px 0 0; }
    .footer-copyright ul li { margin: 0 10px; line-height: 24px; }
    .footer-social ul { margin: 40px 0; }
    .footer-social ul li { margin: 0 10px; line-height: 24px; }

    .banner-inner .row { height: 205px; }
    .banner-inner h2 { font-size: 26px; padding-right: 0; text-align: center; line-height: 31px; margin-top: -25px; }
    .top-sidebar-mobile { padding: 0 20px; margin-top: -40px; z-index: 11; }
    .top-sidebar-mobile h4 { font-size: 16px; line-height: 22px; }
    .sidebar-view-job {  padding: 25px 20px 35px; border-radius: 9px; }
    .sidebar-view-job .button { font-size: 20px; padding: 10px 34px; margin-top: 20px; display: inline-block; width: auto; }
    .content-section { padding: 35px 0; }
    .editor-block {  padding: 0 8px; }
    .content-section .content-block h4 { font-size: 18px; line-height: 26px; padding-top: 30px; }
    .content-section .content-block ul li { padding-left: 35px; padding-bottom: 10px; line-height: 16px; }
    .content-section .content-block ul li:before { left: 10px; top: 8px; }
    .content-section .content-block ul { margin: 13px 0 0; }

    .sidebar { margin: 15px 0 20px; padding: 0 8px; }
    .sidebar-info { padding: 30px 20px 20px; font-size: 16px; line-height: 22px; border-radius: 9px; }
    .banner-inner:before { background-image: none; background: rgba(0,0,0,0.7); }
	.one-half, .three-sixths, .two-fourths { width: 100%; padding: 0; }
}

@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url("https://use.typekit.net/aic6ouz.css");

/*-------------------- THEAME CSS ---------------------*/
*,
*:before,
*:after {  -webkit-box-sizing: inherit;  box-sizing: inherit; }
html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; -webkit-box-sizing: border-box; box-sizing: border-box; position: relative; min-height: 100%; }
body {	margin: 0;  }
ul,ol { margin: 0 0 20px; padding: 0 0 0 20px; }
ul { list-style: disc; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 1.5em; }
img { height: auto; max-width: 100%; }
a { color: #ef4c27;  -o-transition: all .2s linear 0s;  transition: all .2s linear 0s; -webkit-transition: all .2s linear 0s; }
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none; }
a:focus{border: 0;outline: 0;}
.alignleft { display: inline; float: left; margin-right: 1.5em; }
.alignright { display: inline; float: right; margin-left: 1.5em; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto;text-align: center; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after
{
	content: "";
	display: table;
	table-layout: fixed;
}
.clear{clear: both;}
.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}

body { font-family:"proxima-nova"; font-style:normal; font-weight:400; font-size: 22px; color: #000000; line-height: 31px; }
h1,h2,h3,h4,h5,h6 { font-family: 'Roboto Condensed', sans-serif; }
h1 { font-size: 36px; }
h2 { font-size: 30px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; }
h5 { font-size: 18px; }
h6 { font-size: 16px; }
.button, button, input[type=button] { font-size: 20px; color: #FFF; text-align: center; font-family: 'Roboto Condensed', sans-serif; text-transform: uppercase; padding: 10px 43px; background: #FC5131;
box-shadow: 0 4px 0 0 rgba(0,0,0,0.30); border-radius: 100px;  -o-transition: all .2s linear 0s;  transition: all .2s linear 0s; -webkit-transition: all .2s linear 0s; }
.button:hover,.button:focus, button:hover, button:focus, input[type=button]:hover, , input[type=button]:focus { background: #FFF; color: #FC5131; box-shadow: none; }
button:hover, input[type=button] { cursor: pointer; border: 2px solid #FC6131; box-shadow: none; }
.button.btn-transparent { font-size: 20px; color: #FC5131; text-align: center; font-family: 'Roboto Condensed', sans-serif; text-transform: uppercase;
padding: 10px 43px; border: 2px solid #FC5131; border-radius: 100px; background: transparent; box-shadow: none; transition: all .2s linear 0s; -webkit-transition: all .2s linear 0s; }
.button.btn-transparent:hover,.button.btn-transparent:focus { border: 2px solid #FFF; color: #FFF; }
.img-cover { background-size: cover; background-position: center center; background-repeat: no-repeat; }
.img-cover img { opacity: 0; width: 100%; }


/*-------------- HEADER CSS ----------------*/
.logo { margin-left: -35px; }
.logo a { display: inline-block; max-width: 100%; }
.site-header { background: #FC5131; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50); position: relative; z-index: 31; }
.site-header .row { height: 150px; -webkit-align-items: center; -webkit-flex-direction: row; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }

.navigation ul { margin: 0; padding: 0; list-style: none }
.navigation ul li { display: inline-block; padding: 0 21px; position: relative; }
.navigation ul li a { padding: 10px; display: inline-block; font-size: 24px; color: #FFFFFF; text-transform: uppercase; font-family: 'Roboto Condensed', sans-serif; position: relative; transition: all .2s linear 0s; -webkit-transition: all .2s linear 0s }
.navigation ul li a:after { content: ""; position: absolute; bottom: 7px; left: 10px; right: 10px; height: 3px; background: #FFF; }
.navigation ul li:last-child a:after { right: 0; }
.navigation ul li:last-child,.navigation ul li:last-child a { padding-right: 0; }

.navigation ul li > a:hover,.navigation ul li.current-menu-ancestor > a,.navigation ul li.current-menu-item > a { color: #303036; }
.navigation ul li ul.sub-menu { display: none; }
.navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu { display: block;position: absolute;top: 100%;left: 0;width: 200px;background-color: #FC5131; padding: 5px 20px; z-index: 2;text-align: left;margin-top: 0; }
ul.sub-menu li { display: block; padding: 5px 0!important; line-height: 22px !important; }
ul.sub-menu li a { padding: 5px 10px !important; }
ul.sub-menu li a:after { content: none !important; }
ul.sub-menu li.open > ul.sub-menu, ul.sub-menu li:hover > ul.sub-menu { top: 0px; left: 100%; padding: 0 20px 5px; }

/*------------------- BANNER CSS ------------------*/
.bx-wrapper .bx-viewport { box-shadow: none; border: 0px; left: 0; }
ul.bxslider { margin: 0; padding: 0; list-style: none; }
ul.bxslider li { background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; }
ul.bxslider li:before { content: ""; position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: rgba(0,0,0,0.4); z-index: 1;}
ul.bxslider li img { opacity: 0; width: 100%; min-height: 640px; }
.banner-content { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%,-50%); transform: translate(-50%,-50%); color: #FFF; z-index: 1; text-align: center; max-width: 960px; width: 100%; background: rgba(0,0,0,0.60); border-radius: 9px; padding: 40px 125px; font-size: 20px; line-height: 24px; }
.banner-content h2 { font-size: 34px; text-transform: uppercase; padding-bottom: 5px; padding-top: 5px; }
.banner-content .button { margin-top: 15px; }

/*------------- FOOTER CSS ---------------*/
.site-footer { background: #050401; color: #FFF; }
.footer-top { padding: 45px 0 55px; background: #303036; }
.job-alerts { max-width: 740px; margin: 0 auto; }
.job-alerts h2 { font-size: 34px; color: #FFFFFF; text-transform: uppercase; padding-bottom: 15px; }
.job-alerts h2 span { margin-right: 20px; vertical-align: middle;  }
.job-alerts h2 span img { vertical-align: top; }
.signip-block { -webkit-align-items: center; -webkit-flex-direction: row; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row;
flex-direction: row; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.signup-text { text-align: left; font-size: 20px; line-height: 24px; }
.signup-text p { margin-bottom: 0; }

.footer-bottom { background: #000; padding: 75px 0 65px; }
.footer-info .row { -webkit-align-items: center; -webkit-flex-direction: row; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row;
flex-direction: row; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.footer-copyright .menu-footer-menu-container { display: inline-block; }
.footer-info { max-width: 1045px; margin: 0 auto; text-align: center; }
.footer-info .footer-call span,.footer-info .footer-mail span { font-size: 30px; color: #F6511D; margin-right: 20px; }
.footer-info .footer-call a,.footer-info .footer-mail a { font-size: 30px; color: #0F82AA; letter-spacing: 0; text-align: center; font-weight: 700; }
.footer-copyright ul {padding: 0; margin: 24px 0 0; list-style: none;text-align: center; }
.footer-copyright ul.menu { margin: 0; }
.footer-copyright ul li { display: inline-block; margin: 0 10px; font-size: 14px; color: #757575; letter-spacing: 0.02em; }
.footer-copyright ul li a { display: inline-block; color: #757575; }
.footer-social ul {padding: 0; margin: 24px 0 0; list-style: none;text-align: center; }
.footer-social ul li { display: inline-block; margin: 0 10px; font-size: 34px; color: #757575; letter-spacing: 0.02em; }
.footer-social ul li a { display: inline-block; color: #F6511D; }

.weblinx { text-align: center; font-size: 14px; color: #757575; letter-spacing: 0.02em; margin: 20px 0 0; }
.weblinx a { color: inherit; }
.weblinx a:focus, .weblinx a:hover { color: inherit; text-decoration: underline; }

/*------------- VIEW JOB CSS ----------------*/
.view-job-section { background: #303036; color: #FFF; padding: 70px 0 105px; }
.job-image { position: relative; border-radius: 9px 9px 0 0; }
.job-image:before { content: ""; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 55%, rgba(0,0,0,0.4) 81%); border-radius: 9px 9px 0 0; }
.job-title { position: absolute; left: 0; right: 0; text-align: center; bottom: 10px; text-transform: uppercase; }
.job-title h3 { font-size: 29px; }
.job-descreption { text-align: center; padding: 25px 40px; background: #0F82AA; box-shadow: 0 4px 0 0 rgba(15,15,15,0.33); border-radius: 0 0 9px 9px; font-size: 16px;
line-height: 22px; }
.job-descreption p { padding-bottom: 10px; }
.view-job-block .btn-job { position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); margin-top: -26px; z-index: 2; }

/*-----------------HOT JOB CSS ---------------*/
.hot-job-section { padding: 55px 0; position: relative; overflow-x: hidden; }
.hot-job-title { position: relative; }
.hot-job-title h2 { font-size: 34px; color: #0F82AA; text-transform: uppercase; padding-bottom: 22px; padding-left: 50px; }
.hot-job-title:before { content: ""; position: absolute; top: 50%; left: -100%; width: 100%; height: 4px; background: #007aa5; transform: translateY(-50%); margin-top: -11px; border-radius: 9px; margin-left: 28px; }

.acJSearchM ul { display: flex; flex-wrap: wrap; list-style: none; margin: 0 -15px; padding: 0; height: auto !important; width: auto !important; }
.acJSearchM ul li { flex: 0 0 25%; padding: 0 15px 24px; }
.acJSearchM ul li a {
    align-items: center;
    background: #303036;
    border-radius: 9px;
    color: #FC5131;
    display: block;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
    height: 105px;
    line-height: 24px;
    padding: 0 20px;
    text-align: center;
    width: 100%;
}


.hot-job-btn { padding: 10px 50px 10px 0; position: relative; }
.hot-job-btn .button:hover,.hot-job-btn .button:focus { background: #FC5131; color: #FFF; box-shadow: 0 4px 0 0 rgba(0,0,0,0.30); }
.hot-job-btn:after { content: ""; position: absolute; top: 50%; left: 100%; width: 100%; height: 4px; background: #007aa5; transform: translateY(-50%);     margin-left: -27px; border-radius: 9px; }

/*-------- INNER PAGE CSS ------------*/
.banner-inner { position: relative; }
.banner-inner:before { content: ""; position: absolute; top: 0; bottom: 0; right: 0; left: 0; background-image: linear-gradient(90deg, rgba(0,0,0,0.24) 30%, rgba(0,0,0,0.48) 51%); }
.banner-inner .row { height: 312px; -webkit-align-items: center; -webkit-flex-direction: row; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -webkit-box-align: center; -ms-flex-align: center; align-items: center; color: #FFF; }
.banner-inner h2 { font-size: 45px; text-shadow: 0 2px 4px rgba(0,0,0,0.50); text-transform: uppercase; padding-right: 40px; }
.banner-inner h2 span { color: #fc5131; }

.content-section { padding: 60px 0; }
.content-section p { padding-bottom: 15px; }

.content-section .content-block h4 { font-size: 25px; color: #0F82AA; font-family: "proxima-nova"; font-weight: 700; padding-top: 30px; display: block; clear: both; }
.content-section .content-block ul { padding: 0; margin: 28px 0 0; list-style: none; }
.content-section .content-block ul li { padding-left: 35px; padding-bottom: 11px; display: block; position: relative; }
.content-section .content-block ul li:before { content: ""; position: absolute; top: 15px; left: 13px; width: 4px; height: 4px; background: #323223; border-radius: 100%; }
.sidebar { margin-left: 45px; margin-top: -265px; position: relative; z-index: 11; }
.sidebar-view-job { padding: 45px 35px; background: #0F82AA; box-shadow: 0 4px 0 0 rgba(0,0,0,0.25); border-radius: 9px 9px 0 0; color: #FFF; position: relative; }
.sidebar-view-job h4 { line-height: 30px; font-family: "proxima-nova"; font-weight: 700; }
.sidebar-view-job .button { font-size: 26px; width: 100%; margin-top: 20px; margin-bottom: 7px; }
.sidebar-info { padding: 35px 35px 60px; font-size: 20px; line-height: 30px; margin-bottom: 60px; background: #303036; border-radius: 0 0 9px 9px; color: #FFF; box-shadow: 0 4px 0 0 rgba(0,0,0,0.30); }
.sidebar-info a { color: #FFF; }




/*--------- COLUMN SHORTCODE CSS --------------*/
/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;
$column-gap: 16em / 16;

/* ------------------------------------------------------------------------ *\
 * Grid (based loosely on https://v4-alpha.getbootstrap.com/layout/grid/)
\* ------------------------------------------------------------------------ */
.row [class*="col-"].-nomargin p:first-of-type, .row [class*="col-"].-nomargin p:last-of-type { display: none !important; }
.row [class*="col-"].-nomargin ul { margin-top: 0 !important; }
.row {
    & {
        display: flex;
        flex-wrap: wrap;
    }

    & > [class*="col-"] {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
    }

    & > [class*="col-"].-nogrow {
        flex-grow: 0;
    }

    & > [class*="col-"].-noshrink {
        flex-shrink: 0;
    }

    &.-padded {
        width: calc(100% + #{remify($column-gap, 16)});
    }

    &.-padded > [class*="col-"] {
        padding-right: remify($column-gap, 16);
    }

    &.-padded.-tight {
        width: calc(100% + #{remify($column-gap * 0.75, 16)});
    }

    &.-padded.-tight > [class*="col-"] {
        padding-right: remify($column-gap * 0.75, 16);
    }

    &.-padded.-loose {
        width: calc(100% + #{remify($column-gap * 1.25, 16)});
    }

    &.-padded.-loose > [class*="col-"] {
        padding-right: remify($column-gap * 1.25, 16);
    }

    &.-nowrap {
        flex-wrap: nowrap;
    }

    &.-wrapreverse {
        flex-wrap: wrap-reverse;
    }

    &.-center {
        align-items: center;
        justify-content: center;
    }

    &:not(.-column).-hcenter {
        justify-content: center;
    }

    &:not(.-column).-vcenter {
        align-items: center;
    }

    &:not(.-column).-top {
        align-items: flex-start;
    }

    &:not(.-column).-right {
        justify-content: flex-end;
    }

    &:not(.-column).-bottom {
        align-items: flex-end;
    }

    &:not(.-column).-left {
        justify-content: flex-start;
    }

    &.-stretch {
        align-items: stretch;
    }

    &.-around {
        justify-content: space-around;
    }

    &.-between {
        justify-content: space-between;
    }

    &:not(.-column).-reverse {
        flex-direction: row-reverse;
    }

    &.-column {
        flex-direction: column;
    }

    &.-column.-vcenter {
        justify-content: center;
    }

    &.-column.-top {
        justify-content: flex-start;
    }

    &.-column.-right {
        align-items: flex-end;
    }

    &.-column.-bottom {
        justify-content: flex-end;
    }

    &.-column.-left {
        align-items: flex-start;
    }

    &.-column.-hcenter {
        align-items: center;
    }

    &.-column.-reverse {
        flex-direction: column-reverse;
    }
}

.col-auto {
    & {
        flex-basis: auto;
    }
}

@for $i from 0 through 12 {
    .col-#{$i} {
        flex-basis: ($i / 12) * 100%;
    }
}

@media screen and (min-width: $xs-break) {
    .col-xs-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-xs-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $s-break) {
    .col-s-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-s-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $m-break) {
    .col-m-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-m-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $l-break) {
    .col-l-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-l-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}

@media screen and (min-width: $xl-break) {
    .col-xl-auto {
        & {
            flex-basis: auto;
        }
    }

    @for $i from 0 through 12 {
        .col-xl-#{$i} {
            flex-basis: ($i / 12) * 100%;
        }
    }
}
